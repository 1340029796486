import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const MentionsLegalesPage = () => (
  <Layout>
    <SEO title="Mentions légales" />
    <section className="section">
        <div className="container">
            <h1 className="title"> Mentions Légales </h1>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 1. Informations relatives à l'hébergeur </h2>
            <h3 className="title is-5"> Nom </h3>
            <p className="content">Netlify</p>
            <h3 className="title is-5"> Adresse du siège social </h3> 
            <p className="content">2325 3rd St #215, San Francisco, CA 94107, États-Unis</p>
            <h3 className="title is-5"> Numéro de téléphone </h3>
            <p className="content">+1 415-691-1573</p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 2. Informations relatives à l'éditeur</h2>
            <h3 className="title is-5"> Raison sociale </h3>
            <p className="content">AIR WATER SYSTEM</p>
            <h3 className="title is-5"> Adresse </h3>
            <p className="content">4 Rue Frederic Chopin 69780 MIONS</p>
            <h3 className="title is-5"> Téléphone </h3>
            <p className="content">0954688717</p>
            <h3 className="title is-5"> E-mail </h3>
            <p className="content">contact@air-water-system.fr</p>
            <h3 className="title is-5"> Directeur de publication </h3>
            <p className="content">Wassim DHIFALLAH</p>
            <h3 className="title is-5"> RCS </h3>
            <p className="content">51167783300028</p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 3. Conditions d'utilisation </h2>
            <p className="content">
                Le site est accessible par l'url airwater-system.com.
                Son utilisation est régie par les présentes conditions générales. En utilisant le site, vous reconnaissez avoir pris connaissance de ces conditions et les avoir acceptées. Celles-ci pourront être modifiées à tout moment et sans préavis par la société AIR WATER SYSTEM.
                AIR WATER SYSTEM ne saurait être tenue pour responsable en aucune manière d'une mauvaise utilisation du service.
            </p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 4. Limitation de la responsabilité </h2>
            <p className="content">
                Les informations contenues sur ce site sont aussi précises que possible et le site est périodiquement remis à jour, mais peut toutefois contenir des inexactitudes, des omissions ou des lacunes. Si vous constatez une erreur ou ce qui peut être un dysfonctionnement, merci de bien vouloir le signaler par email en décrivant le problème de la manière la plus précise possible.
                Tout contenu téléchargé se fait aux risques et périls de l'utilisateur et sous sa seule responsabilité. En conséquence, AIR WATER SYSTEM ne saurait être tenu responsable d'un quelconque dommage subi par l'ordinateur de l'utilisateur ou d'une quelconque perte de données consécutives au téléchargement. Les photos sont non contractuelles.
                Les liens hypertextes mis en place sur ce site internet en direction d'autres sites internet ne sauraient engager la responsabilité de AIR WATER SYSTEM.
            </p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 5. Litiges </h2>
            <p className="content">
                Les présentes conditions sont régies par les lois françaises et toute contestation ou litige qui pourrait naître de l'interprétation ou de l'exécution de celles-ci seront de la compétence exclusive des tribunaux dont dépend le siège social de la société AIR WATER SYSTEM. La langue de référence, pour le règlement de contentieux éventuels, est le français.
            </p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 6. Données personnelles </h2>
            <p className="content">
                En application de la loi informatique et libertés, les internautes disposent d'un droit d'accès, de rectification, de modification et de suppression concernant les données qui les concernent personnellement. Ce droit peut être exercé par voie postale auprès de AIR WATER SYSTEM 4 Rue Frederic Chopin 69780 MIONS ou par voie électronique à l'adresse email suivante : airws@live.fr.
                Les informations personnelles collectées sont confidentielles et ne sont en aucun cas transmises à des tiers hormis pour l'éventuelle bonne exécution de la prestation commandée par l'internaute. En vertu de la loi n° 2000-719 du 1er août 2000, les coordonnées déclarées par l'acheteur pourront être communiquées sur réquisition des autorités judiciaires.
            </p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 7. Propriété intellectuelle </h2>
            <p className="content">
                Tout le contenu du présent site, incluant, de façon non limitative, les graphismes, images, textes, vidéos, animations, sons, logos, gifs et icônes ainsi que leur mise en forme sont la propriété exclusive de la société AIR WATER SYSTEM à l'exception des marques, logos ou contenus appartenant à d'autres sociétés partenaires ou auteurs.
                Toute reproduction, distribution, modification, adaptation, retransmission ou publication, même partielle, de ces différents éléments est strictement interdite sans l'accord express par écrit de AIR WATER SYSTEM.
            </p>
        </div>
    </section>
    <section className="section">
        <div className="container">
            <h2 className="title is-4"> 8. Cookies </h2>
            <p className="content">
                Pour des besoins de statistiques et d'affichage, le présent site utilise des cookies. Il s'agit de petits fichiers textes stockés sur votre disque dur afin d'enregistrer des données techniques sur votre navigation. Certaines parties de ce site ne peuvent être fonctionnelles sans l'acceptation de cookies.
            </p>
        </div>
    </section>
  </Layout>
)

export default MentionsLegalesPage;
